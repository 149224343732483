<template>
  <div>
    <div v-if="!is_sup">
      <form class="input-group" @submit.prevent="access(pin)">
        <input
          class="form-control"
          placeholder="PIN"
          type="password"
          v-model="pin"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="access(pin)"
          >
            Access !
          </button>
        </div>
      </form>
      <br />
      <!-- <personal-desh /> -->
    </div>

    <div v-if="is_sup">
      <div class="row">
        <div class="col-12 mb-1">
          <form @submit.prevent="gettran()">
            <div class="row">
              <div class="col-6 mb-1">
                <label for="fromdate">From date</label>
                <b-form-datepicker
                  id="fromdate"
                  v-model="postdata.fromdate"
                  class="mb-1"
                />
              </div>
              <div class="col-6 mb-1">
                <label for="todate">To date</label>
                <b-form-datepicker
                  id="todate"
                  v-model="postdata.todate"
                  class="mb-1"
                />
              </div>
            </div>
            <div class="mb-1">
              <!-- basic search -->
              <input
                class="form-control"
                placeholder="From MFS"
                list="frommfs-list"
                type="text"
                v-model="postdata.number"
              />
              <datalist id="frommfs-list">
                <option
                  :value="d.number"
                  v-for="(d, index) in predata"
                  :key="index"
                >
                  {{ d.tran_date }} {{ "|  ৳" }} {{ d.total_amount }}
                </option>
              </datalist>
            </div>
            <div class="mb-1">
              <!-- basic search -->
              <input
                class="form-control"
                placeholder="TO MFS"
                list="tomfs-list"
                type="text"
                v-model="postdata.account"
              />
              <datalist id="tomfs-list">
                <option value="+8801713222343"></option>
                <option value="+8801873708000"></option>
                <option value="+8801990003339"></option>
              </datalist>
            </div>
            <div class="mb-1">
              <!-- basic search -->
              <input
                class="form-control"
                placeholder="MFS TrxID"
                list="trx-list"
                type="text"
                v-model="postdata.trx"
              />
              <datalist id="trx-list">
                <option
                  :value="d.gateway_tran_id"
                  v-for="(d, index) in predata"
                  :key="index"
                >
                  {{ d.tran_date }} {{ "|  ৳" }} {{ d.total_amount }}
                </option>
              </datalist>
            </div>

            <div class="input-group mb-1">
              <!-- basic search -->
              <input
                class="form-control"
                placeholder="Amount"
                list="user-list"
                type="text"
                v-model="postdata.total_amount"
              />

              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="gettran()"
                >
                  Search !
                </button>
              </div>
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="reset()"
                >
                  Reset !
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-if="is_result">
        <!-- success -->
        <b-alert variant="success" show>
          <h4 class="alert-heading">Success</h4>
          <div class="alert-body">
            <div class="row">
              <div class="col-12">
                <span>Message: {{ result.message }}</span>
              </div>
            </div>
            <div class="row" v-if="result.is_withdraw == false">
              <div class="col-12">
                <span> DTM ID: {{ result.dtm_id }}</span>
              </div>
              <div class="col-12">
                <span> Full Name: {{ result.fullName }}</span>
              </div>
              <div class="col-12">
                <span> Mobile Number: {{ result.mobile1 }}</span>
              </div>
              <div class="col-12" v-if="result.sms_response">
                <span> SMS Status: </span>
                <b
                  style="color: red"
                  v-if="result.sms_response.api_response_code == 400"
                >
                  {{ result.sms_response.api_response_message }}</b
                >
                <b v-else> {{ result.sms_response.api_response_message }}</b>
              </div>
            </div>
          </div>
        </b-alert>
      </div>
      <div v-if="is_data">
        <b-card no-body class="card-statistics" hidden>
          <b-card-header>
            <b-card-title
              >Statistics
              <span style="color: green">({{ user }})</span></b-card-title
            >
            <b-card-text class="mr-25 mb-0">
              <a href="#" @click="gettran()">
                Refresh
                <b-badge variant="light-primary">
                  {{ total }}
                </b-badge>
              </a>
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                v-for="item in statisticsItems"
                :key="item.icon"
                md="3"
                sm="6"
                class="mb-2 mb-md-0"
                :class="item.customClass"
              >
                <b-media-aside no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" :variant="item.color">
                      <feather-icon size="24" :icon="item.icon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ item.title }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media-aside>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-table small :fields="fields" :items="items" responsive="sm">
          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }} /
            <b-badge pill>
              <span>{{ data.item.id }}</span>
            </b-badge>
          </template>

          <!-- A custom formatted column -->
          <template #cell(gateway_tran_id)="data">
            <!-- <b-badge variant="light-primary">
          {{ data.value }}
        </b-badge> -->
            <b-badge pill variant="primary" v-if="data.item.is_auth">
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>{{ data.value }}</span>
            </b-badge>
            <b-badge
              href="#"
              @click="updatetran(data.item.id)"
              variant="danger"
              v-else
            >
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>{{ data.value }}</span>
            </b-badge>
          </template>
          <!-- A custom formatted column -->
          <template #cell(number)="data">
            <b-badge pill>
              <span>{{ data.value }}</span>
            </b-badge>
          </template>
          <template #cell(account)="data">
            <b-badge pill variant="warning">
              <span>{{ data.value }}</span>
            </b-badge>
          </template>

          <!-- A custom formatted column -->
          <template #cell(Popularity)="data">
            <b-progress
              :value="data.value.value"
              max="100"
              :variant="data.value.variant"
              striped
            />
          </template>
          <!-- A custom formatted column -->
          <template #cell(pay_type)="data">
            <b-badge
              variant="dark"
              class="badge-glow"
              v-if="data.value == 'Cash'"
            >
              {{ data.value }}
            </b-badge>
            <b-badge class="badge-glow" v-else>
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(is_auth)="data">
            <b-badge variant="success" v-if="data.value">
              <feather-icon icon="ShieldIcon" class="mr-25" />
              <span> Verified </span>
            </b-badge>
            <b-badge
              href="#"
              @click="postauth(data.item.id)"
              variant="danger"
              v-else
            >
              <feather-icon icon="ShieldOffIcon" class="mr-25" />
              <span>Unverified</span>
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge v-if="!data.item.is_auth">
              <feather-icon icon="GitBranchIcon" class="mr-25" />
              <span> Adjustment </span>
            </b-badge>
            <b-badge
              href="#"
              @click="modaldata(data.index)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal
              variant="danger"
              v-else
            >
              <feather-icon icon="GitPullRequestIcon" class="mr-25" />
              <span>Adjustment</span>
            </b-badge>
          </template>

          <!-- A virtual composite column -->
          <template #cell(total_amount)="data">
            <b-badge pill variant="success">
              {{ "৳" + data.value }}
            </b-badge>
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell(tran_date)="data">
            <b-badge pill variant="dark">{{ data.value }}</b-badge>
          </template>
          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
      </div>
    </div>
    <!-- model view  -->
    <b-modal
      id="modal"
      ref="refmodal"
      centered
      size="lg"
      :title="`Transaction Details ( ID: ${info.id} )`"
      @ok="adjustment"
    >
      <b-card-text>
        <div class="row">
          <div class="col-2 mb-1">
            <b-badge pill variant="primary" v-if="info.tran_val == 1">
              <span>DTM-PAY</span>
            </b-badge>
            <b-badge pill variant="warning" v-else-if="info.tran_val == 6">
              <span>SUNDY-PAY</span>
            </b-badge>
            <b-badge pill v-else>
              <span>REG-PAY</span>
            </b-badge>
          </div>

          <div class="col-1 mb-1">
            <b-badge
              variant="dark"
              class="badge-glow"
              v-if="info.pay_type == 'Cash'"
            >
              {{ info.pay_type }}
            </b-badge>
            <b-badge class="badge-glow" v-else>
              {{ info.pay_type }}
            </b-badge>
          </div>
          <div class="col-9 mb-1">
            <b-badge variant="success" v-if="info.is_auth">
              <feather-icon icon="ShieldIcon" class="mr-25" />
              <span> Verified </span>
            </b-badge>
            <b-badge
              href="#"
              @click="postauth(info.id)"
              variant="danger"
              v-else
            >
              <feather-icon icon="ShieldOffIcon" class="mr-25" />
              <span>Unverified</span>
            </b-badge>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-1">
            <label for="tran">Trx ID</label>
            <input
              class="form-control"
              placeholder="Trx ID"
              type="text"
              id="tran"
              v-model="info.gateway_tran_id"
              readonly
            />
          </div>
          <div class="col-12 col-lg-3 mb-1">
            <label for="from">From MFS</label>
            <input
              class="form-control"
              placeholder="Mobile Number"
              type="text"
              id="from"
              v-model="info.number"
              readonly
            />
          </div>
          <div class="col-12 col-lg-3 mb-1">
            <label for="to">To MFS</label>
            <input
              class="form-control"
              placeholder="Date of Birth"
              type="text"
              id="to"
              v-model="info.account"
              readonly
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="ptype">Payment Type</label>
            <input
              class="form-control"
              placeholder="Account Status"
              type="ptype"
              v-model="info.pay_type"
              readonly
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="trandate">Trx Date</label>
            <input
              class="form-control"
              placeholder="Opening Date"
              type="text"
              id="trandate"
              v-model="info.tran_date"
              readonly
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-1 input-group input-group-merge">
            <div class="input-group-prepend">
              <div class="input-group-text">Adjustment Amount</div>
            </div>
            <input
              class="form-control"
              placeholder="Panding Subcription Fee"
              type="number"
              v-model="info.store_amount"
              readonly
            />
            <div class="input-group-append">
              <div class="input-group-text">৳</div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-1 input-group input-group-merge">
            <div class="input-group-prepend">
              <div class="input-group-text">Total Amount</div>
            </div>
            <input
              class="form-control"
              placeholder="Advance Subcription Fee"
              type="number"
              v-model="info.total_amount"
              readonly
            />
            <div class="input-group-append">
              <div class="input-group-text">৳</div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12 col-lg-6 mb-1">
            <label for="created">Created By</label>
            <input
              class="form-control"
              placeholder="Created By"
              type="text"
              id="created"
              v-model="info.created_by"
              readonly
            />
          </div>
          <div class="col-12 col-lg-3 mb-1">
            <label for="city1">City</label>
            <input
              class="form-control"
              placeholder="City"
              type="text"
              id="city1"
              v-model="ip.city"
              readonly
            />
          </div>
          <div class="col-12 col-lg-3 mb-1">
            <label for="country">Country</label>
            <input
              class="form-control"
              placeholder="Country"
              type="text"
              id="country"
              v-model="ip.country"
              readonly
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="isp">Internet Service Provider</label>
            <input
              class="form-control"
              placeholder="Internet Service Provider"
              type="isp"
              v-model="ip.organization_name"
              readonly
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="ip">IP Address</label>
            <input
              class="form-control"
              placeholder="IP Address"
              type="text"
              id="ip"
              v-model="info.ip_address"
              readonly
            />
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12 mb-1">
            <label for="paytype">Adjustment Type</label>
            <select class="custom-select" v-model="tran_val" id="paytype">
              <option value="1">DTM-PAY</option>
              <option value="2">New-REG</option>
              <option value="0">Withdraw</option>
            </select>
          </div>
          <div class="col-12 mb-1" v-if="tran_val == 1">
            <label for="dtm_id">DTM ID</label>
            <input
              class="form-control"
              placeholder="########"
              type="text"
              id="dtm_id"
              list="dtmid-list"
              v-model="dtm_id"
              @keyup="getdtmdata()"
            />
            <datalist id="dtmid-list">
              <option
                :value="d.dtm_id"
                v-for="(d, index) in predtmdata"
                :key="index"
              >
                {{ d.dtm_profile.fullName }}
              </option>
            </datalist>
          </div>
          <div class="col-12 mb-1" v-if="tran_val == 2">
            <label for="reg_id">REG Number</label>
            <input
              class="form-control"
              placeholder="+880**********"
              type="text"
              id="reg_id"
              list="reg-list"
              v-model="reg_id"
              @keyup="getregdata()"
            />
            <datalist id="reg-list">
              <option
                :value="d.customer_id"
                v-for="(d, index) in preregdata"
                :key="index"
              >
                {{ d.fullName }}
              </option>
            </datalist>
          </div>
          <div class="col-6" v-if="tran_val != 0">
            <label for="sb-inline" class="mr-1">Month</label>
            <!-- <br /> -->
            <b-form-spinbutton
              id="sb-inline"
              v-model="month"
              v-on="dtm_month()"
            />
          </div>
          <div class="col-6" v-if="tran_val != 0">
            <label for="fee">DTM Amount</label>
            <div class="input-group mb-1">
              <!-- basic search -->
              <input
                class="form-control"
                id="fee"
                placeholder="300.00"
                :value="dtm_pay"
                type="number"
                readonly
              />
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="dtmreset()"
                >
                  Reset !
                </button>
              </div>
            </div>
          </div>

          <div class="col-12 mb-1" v-if="tran_val != 0">
            <label for="donate">Donate Amount</label>
            <input
              class="form-control"
              placeholder="0.00"
              type="text"
              id="donate"
              v-model="donate_amount"
              readonly
            />
            <b-alert
              v-height-fade.appear
              variant="danger"
              :show="is_donate_ammount"
              class="mb-0 mt-1"
            >
              <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50" />
                Amount should be positive figure
              </div>
            </b-alert>
          </div>
        </div>
      </b-card-text>
    </b-modal>
    <cash-pay v-if="is_w == true" />
  </div>
</template>

<script>
import {
  BTable,
  BProgress,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
  BFormDatepicker,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSpinbutton,
  BFormCheckbox,
  BFormSelect,
  BAlert,
} from "bootstrap-vue";
// import PersonalDesh from "../extensions/card-statistic/CardStatisticsGroup.vue";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import vSelect from "vue-select";
import axios from "axios";
// import AppTourVue from "../../@core/components/app-tour/AppTour.vue";
import CashPay from "../../views/extensions/CashPay.vue";

export default {
  name: "adjustment",
  components: {
    BTable,
    BProgress,
    BBadge,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BFormDatepicker,
    vSelect,
    // PersonalDesh,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSpinbutton,
    BFormCheckbox,
    BFormSelect,
    BAlert,
    CashPay,
  },
  directives: {
    "height-fade": heightFade,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      user: "",
      postdata: {
        fromdate: "",
        todate: "",
        number: "",
        account: "",
        trx: "",
        total_amount: "",
      },
      data: "",
      pin: "",
      is_data: false,
      is_sup: false,
      is_w: false,
      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "tran_date", label: "Transaction Date" },
        { key: "number", label: "From MFS" },
        { key: "account", label: "TO MFS" },
        "pay_type",
        // A regular column
        { key: "gateway_tran_id", label: "Tnx/Money Recept" },
        // A virtual column made up from two fields
        { key: "total_amount", label: "Total Amount" },
        // A regular column
        { key: "is_auth", label: "Auth Status" },
        { key: "status", label: "Action" },
      ],
      items: [],
      info_id: "",
      info: {},
      dtm_info: {
        dtm_profile: {
          fullName: "",
        },
      },
      tran_val: 1,
      dtm_id: "",
      reg_id: "",
      reg_pay: 200,
      month: 0,
      dtm_pay: 0,
      is_donate_ammount: false,
      ip: {},
      predata: [],
      predtmdata: [],
      preregdata: [],
      total: "৳0",
      geo: "",
      is_result: false,
      result: {},
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "0/0",
          subtitle: "Total/Monthly Transaction",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserIcon",
          color: "light-info",
          title: "0/0",
          subtitle: "Total/Monthly DTM Registration",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "AwardIcon",
          color: "light-success",
          title: "৳0",
          subtitle: "This Month`s Collection",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-danger",
          title: "৳0",
          subtitle: "Panding Amount",
          customClass: "",
        },
      ],
    };
  },
  computed: {
    statusChack() {
      const that = this;
      if (that.status == true) {
        return "Active";
      } else if (that.status == false) {
        return "Inactive";
      } else {
        return "";
      }
    },
    buttonStatus() {
      const that = this;
      if (that.status == true) {
        return true;
      } else if (that.status == false) {
        return false;
      } else {
        return false;
      }
    },
    chack() {
      const that = this;
      if (that.formdata.dtm_amount == 0 && that.formdata.donate_amount == 0) {
        return false;
      } else {
        return true;
      }
    },
    pay_m() {
      if (this.formdata.pay_type == "Cash") {
        return false;
      } else {
        return true;
      }
    },
    total_amount() {
      if (this.formdata.donate_amount) {
        if (this.formdata.donate_amount < 0) {
          return parseFloat(this.formdata.dtm_amount);
        } else {
          return (
            parseFloat(this.formdata.dtm_amount) +
            parseFloat(this.formdata.donate_amount)
          );
        }
      } else {
        return parseFloat(this.formdata.dtm_amount);
      }
    },
    donate_amount() {
      const that = this;
      if (that.tran_val == 2) {
        if (
          parseFloat(this.info.total_amount) -
            (parseFloat(this.reg_pay) + parseFloat(this.dtm_pay)) >=
          0
        ) {
          that.is_donate_ammount = false;
        } else {
          that.is_donate_ammount = true;
        }

        return (
          parseFloat(this.info.total_amount) -
          (parseFloat(this.reg_pay) + parseFloat(this.dtm_pay))
        );
      } else {
        if (
          parseFloat(this.info.total_amount) - parseFloat(this.dtm_pay) >=
          0
        ) {
          that.is_donate_ammount = false;
        } else {
          that.is_donate_ammount = true;
        }

        return parseFloat(this.info.total_amount) - parseFloat(this.dtm_pay);
      }
    },
  },
  methods: {
    adjustment() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);

      if (!that.dtm_id == "" || !that.reg_id == "" || that.tran_val == 0) {
        if (that.donate_amount >= 0) {
          this.$swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, Pay it!",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm() {
              that.$http
                .post(`/dtm/adjustment/pay/${userdata.id}/`, {
                  sundy_id: that.info.id,
                  moneyrecept: that.info.gateway_tran_id,
                  account: that.info.account,
                  number: that.info.number,
                  customer_id: that.reg_id,
                  dtm_id: that.dtm_id,
                  tran_val: that.tran_val,
                  tran_date: that.info.tran_date,
                  pay_type: that.info.pay_type,
                  total_amount: that.info.total_amount,
                  customer_amount: that.reg_pay,
                  dtm_amount: that.dtm_pay,
                  donate_amount: that.donate_amount,
                  userName: userdata.username,
                  ip_address: that.geo.ip,
                  remark: `sundy Cash Payment successfull by ${userdata.username} | sundy ID: ${that.info.id}`,
                  message: `Sundy Cash Payment successfull by ${userdata.username} | sundy ID: ${that.info.id}`,
                })
                .then((response) => {
                  console.log(response);
                  that.is_result = true;
                  that.result = response.data;
                  that.is_w = response.data.is_withdraw;
                })
                .catch((error) => {
                  // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                  console.log(error);
                });
            },
          }).then((result) => {
            if (result.value) {
              this.$swal({
                icon: "success",
                title: "Created!",
                text: "Payment has been Created.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              that.reset();
            }
          });
        } else {
          let variant = "danger";
          this.$bvToast.toast("Ammount is not Adjust", {
            title: `Adjustment Error`,
            variant,
            solid: true,
          });
        }
      } else {
        let variant = "danger";
        this.$bvToast.toast("DTM ID/CutomerID is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    dtm_month() {
      this.dtm_pay = 300 * this.month;
    },
    modaldata(index) {
      const that = this;
      this.info_id = index;
      if (that.items[index].tran_val == 1) {
        that.getdtm_info(that.items[index].dtm_id);
      } else {
        that.dtm_info = {};
      }
      this.info = {};
      this.info = this.items[index];
      this.getip(this.items[index].ip_address);
    },
    access(pin) {
      if (!pin == "") {
        this.$http
          .post(`/dtm/auth/pay/checkpin/all/`, {
            pin: pin,
          })
          .then((response) => {
            this.is_sup = response.data.is_sup;
            if (response.data.message == false) {
              let variant = "success";
              this.$bvToast.toast("PIN Verified ", {
                title: `Access Success`,
                variant,
                solid: true,
              });
            } else {
              let variant = "warning";
              this.$bvToast.toast("PIN Incorrect", {
                title: `Access Faild`,
                variant,
                solid: true,
              });
            }
          })
          .catch((error) => console.log(error));
      } else {
        let variant = "danger";
        this.$bvToast.toast("PIN is Required", {
          title: `Access Faild`,
          variant,
          solid: true,
        });
      }
    },
    postauth(id) {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);

      if (!id == "") {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Approve!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            that.$http
              .post(`/dtm/auth/pay/authpay/${id}/`, {
                user: that.user,
              })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });
          },
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Approved!",
              text: "Payment has been Approved.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            that.gettran();
          }
        });
      } else {
        let variant = "danger";
        this.$bvToast.toast("Transaction ID is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    updatetran(id) {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);
      console.log(that.selected);

      if (!id == "") {
        this.$swal({
          title: "Update Money Recept Number",
          input: "text",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Update",
          showLoaderOnConfirm: true,
          preConfirm(number) {
            that.$http
              .post(`/dtm/auth/pay/updateTran/${id}/`, {
                gateway_tran_id: number,
              })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                console.log(error);
              });
          },
        }).then((result) => {
          if (result.value) {
            this.$swal({
              title: `Updated`,
              // imageUrl: result.value.avatar_url,
              customClass: { confirmButton: "btn btn-primary" },
            });
            that.gettran();
          }
        });
      } else {
        let variant = "danger";
        this.$bvToast.toast("Transaction Id is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    pre() {
      this.$http
        .get(`/dtm/sundy/pay/getpresundy/6/`)
        .then((response) => {
          this.predata = response.data;
        })
        .catch((error) => console.log(error));
    },
    getdtmdata() {
      this.$http
        .get(`/dtm/reg/pay/dtmlist/${this.dtm_id || 0}/`)
        .then((response) => {
          this.predtmdata = response.data;
        })
        .catch((error) => console.log(error));
    },
    getregdata() {
      this.$http
        .get(`/dtm/new/reg/${this.reg_id || 0}/`)
        .then((response) => {
          this.preregdata = response.data;
        })
        .catch((error) => console.log(error));
    },
    gettran() {
      var userdata = JSON.parse(localStorage.getItem("userData"));
      this.is_result = false;
      if (!this.postdata == "") {
        this.$http
          .post(`/dtm/sundy/pay/adjust/6/`, this.postdata)
          .then((response) => {
            this.items = response.data;
          })
          .catch((error) => console.log(error));
        // this.$http
        //   .get(`/dtm/reg/pay/userDash/${this.user}/`)
        //   .then((response) => {
        //     var data = response.data.account;
        //     this.statisticsItems[0].title =
        //       data.total_tran + "/" + data.this_month_tran;
        //     this.statisticsItems[1].title =
        //       data.total_reg + "/" + data.this_month_reg;
        //     this.statisticsItems[2].title = `৳` + data.this_month_amount;
        //     this.statisticsItems[3].title = `৳` + data.panding_amount;
        //     this.total = `৳` + data.total_amount;
        //   })
        //   .catch((error) => console.log(error));
        this.is_data = true;
      } else {
        let variant = "danger";
        this.$bvToast.toast("Query Data is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    reset() {
      const that = this;
      that.postdata.fromdate = "";
      that.postdata.todate = "";
      that.postdata.number = "";
      that.postdata.account = "";
      that.postdata.trx = "";
      that.postdata.total_amount = "";
      that.info = "";
      that.dtm_id = "";
      that.reg_id = "";
      that.tran_val = 1;
      that.month = 0;
      that.dtm_pay = 0;
      that.reg_pay = 200;
      that.is_result = false;
      that.is_w = false;
      this.is_data = false;
      this.pre();
      this.getregdata();
      this.getdtmdata();
      this.selfgetip();
    },
    dtmreset() {
      const that = this;
      that.month = 0;
      that.dtm_pay = 0;
      that.reg_pay = 200;
    },
    async getip(ip) {
      await axios
        .get(`https://get.geojs.io/v1/ip/geo/${ip}.json`)
        .then((response) => {
          this.ip = response.data;
        })
        .catch((error) => console.log(error));
    },
    selfgetip() {
      axios
        .get("https://get.geojs.io/v1/ip/geo.json")
        .then((response) => {
          this.geo = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
  created() {
    // this.gettran();
    this.pre();
    this.getregdata();
    this.getdtmdata();
    this.selfgetip();
    var userdata = JSON.parse(localStorage.getItem("userData"));
    this.is_sup = userdata.is_sup;
  },
};
</script>
