<template>
  <div>
    <div class="row">
      <div class="col-12 mb-1">
        <form class="input-group" @submit.prevent="gettran()">
          <!-- basic search -->
          <input
            class="form-control"
            placeholder="MFS Mobile Number"
            list="dtm-list-sundy"
            type="text"
            v-model="number"
            @keyup="getdata()"
          />
          <datalist id="dtm-list-sundy">
            <option
              :value="d.mobile1"
              v-for="(d, index) in predata"
              :key="index"
            >
              {{ d.fullName }}
            </option>
          </datalist>
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-outline-primary"
              @click="gettran()"
            >
              Search !
            </button>
          </div>
        </form>
      </div>
    </div>
    <hr />
    <div class="col-12 mb-0">
      <div v-if="$can('read', 'DTMPAY')">
        <select
          class="custom-select"
          v-model="selecteddtm"
          v-if="$can('read', 'DTMPAY')"
        >
          <option value="null" selected>Select DTM</option>
          <option
            :value="d.value"
            v-for="(d, index) in optionsdtm"
            :key="index"
          >
            {{ d.text }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="$can('read', 'DTMPAY')">
      <br />
      <b-form-select
        v-model="selecteddtm"
        :options="optionsdtm"
        :select-size="6"
      />
    </div>
    <hr />
    <div class="col-12 mb-0">
      <div v-if="$can('read', 'DTMPAY')">
        <select
          class="custom-select"
          v-model="selected"
          v-if="$can('read', 'DTMPAY')"
        >
          <option value="null" selected>Select Transactions</option>
          <option :value="d.value" v-for="(d, index) in options" :key="index">
            {{ d.text }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="$can('read', 'DTMPAY')">
      <br />
      <b-form-select v-model="selected" :options="options" :select-size="10" />
    </div>

    <div class="">
      <button
        type="button"
        class="btn btn-outline-success mr-1 mt-1"
        @click="updatetran()"
        hidden
      >
        Cash Pay !
      </button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-addsundy
        variant="outline-primary"
        class="mr-1 mt-1"
        :disabled="buttonStatus == false"
      >
        Add Sundy Fund !
      </b-button>
      <button
        type="button"
        class="btn btn-outline-danger mr-1 mt-1"
        @click="cleandata()"
      >
        Clean !
      </button>
    </div>
    <!-- select 2 demo -->
    <b-modal
      id="modal-addsundy"
      title="New Cash Payment"
      ok-title="Pay"
      @ok="newcashtran()"
      cancel-variant="outline-secondary"
    >
      <b-form @submit.prevent="newcashtran()">
        <div class="text-right">
          <b-badge variant="success" class="badge-glow">
            {{ total_amount }}BDT
          </b-badge>
        </div>

        <b-form-group label="Sundy Amount" label-for="donate">
          <b-form-input
            id="donate"
            type="number"
            placeholder="0"
            v-model="formdata.donate_amount"
          />
        </b-form-group>
        <div class="mb-1" v-if="$can('read', 'DTMPAY')">
          <label for="tran">Pay Type</label>
          <select class="custom-select" v-model="formdata.pay_type" id="tran">
            <option value="" selected>Select Transections</option>
            <option
              :value="d.value"
              v-for="(d, index) in pay_type"
              :key="index"
            >
              {{ d.key }}
            </option>
          </select>
        </div>

        <div>
          <label for="example-datepicker">Choose Trx date</label>
          <b-form-datepicker
            id="example-datepicker"
            v-model="tran_date"
            class="mb-1"
            required
          />
        </div>

        <b-form-group
          :label="pay_m ? 'TrxID' : 'Money Recept Number'"
          label-for="money"
        >
          <b-form-input
            id="money"
            type="text"
            placeholder="########"
            v-model="formdata.moneyrecept"
          />
        </b-form-group>
        <div v-if="$can('read', 'DTMPAY')">
          <b-form-group label="From Number" label-for="fromnumber" v-if="pay_m">
            <b-form-input
              id="fromnumber"
              type="text"
              placeholder="00000000000"
              v-model="number"
            />
          </b-form-group>
          <b-form-group label="To Number" label-for="tonumber" v-if="pay_m">
            <b-form-input
              id="tonumber"
              type="text"
              list="to-list"
              placeholder="00000000000"
              v-model="formdata.account"
            />
            <datalist id="to-list">
              <option value="+8801713222343"></option>
              <option value="+8801873708000"></option>
              <option value="+8801990003339"></option>
            </datalist>
          </b-form-group>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
   
<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormSpinbutton,
  BFormDatepicker,
  BBadge,
} from "bootstrap-vue";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "axios";

// import regsearch from "./components/reg-cash-search";

export default {
  name: "addsundy",
  components: {
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSpinbutton,
    BBadge,
    vSelect,
    BFormDatepicker,
    // regsearch,
    BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      number: "+880",
      fullName: "",
      dateOfBirth: "",
      mobile1: "",
      tran_date: "",
      opening_date: "",
      panding_amount: "",
      advance_amount: "",
      month: 0,
      data: {},
      status: null,
      predata: [],
      selecteddtm: null,
      selected: null,
      options: [],
      optionsdtm: [],
      pay_type: [
        // { value: "Cash", key: "Cash" },
        { value: "Bkash", key: "Bkash" },
        { value: "Rocket", key: "Rocket" },
        { value: "Nagad", key: "Nagad" },
        { value: "Bank Transfer", key: "Bank Transfer" },
        { value: "Others", key: "Others" },
      ],
      geo: {
        ip: "172.16.0.1",
      },
      formdata: {
        dtm_amount: 0,
        donate_amount: 0,
        moneyrecept: "",
        account: "",
        number: "",
        pay_type: "Bkash",
      },
    };
  },
  computed: {
    statusChack() {
      const that = this;
      if (that.status == true) {
        return "Active";
      } else if (that.status == false) {
        return "Inactive";
      } else {
        return "";
      }
    },
    buttonStatus() {
      const that = this;
      if (that.status == true) {
        return true;
      } else if (that.status == false) {
        return false;
      } else {
        return false;
      }
    },
    chack() {
      const that = this;
      if (that.formdata.donate_amount == 0) {
        return false;
      } else {
        return true;
      }
    },
    pay_m() {
      if (this.formdata.pay_type == "Cash") {
        return false;
      } else {
        return true;
      }
    },
    total_amount() {
      if (this.formdata.donate_amount) {
        if (this.formdata.donate_amount < 0) {
          return parseFloat(this.formdata.dtm_amount);
        } else {
          return (
            parseFloat(this.formdata.dtm_amount) +
            parseFloat(this.formdata.donate_amount)
          );
        }
      } else {
        return parseFloat(this.formdata.dtm_amount);
      }
    },
  },
  methods: {
    dtm_month() {
      this.formdata.dtm_amount = 300 * this.month;
    },
    newcashtran() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);

      if (!that.number == "" && !that.formdata.moneyrecept == "" && that.tran_date != "") {
        if (that.status == true) {
          if (that.chack) {
            this.$swal({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, Pay it!",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
              showLoaderOnConfirm: true,
              preConfirm() {
                that.$http
                  .post(`/dtm/sundy/pay/sundyPay/${userdata.id}/`, {
                    moneyrecept: that.formdata.moneyrecept,
                    account: that.formdata.account,
                    number: that.number,
                    tran_date: that.tran_date,
                    pay_type: that.formdata.pay_type,
                    total_amount: that.total_amount,
                    userName: userdata.username,
                    ip_address: that.geo.ip,
                    remark: `Sundy Payment successfull by ${userdata.username}`,
                    message: `Sundy Payment successfull by ${userdata.username}`,
                  })
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((error) => {
                    // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                    console.log(error);
                  });
              },
            }).then((result) => {
              if (result.value) {
                this.$swal({
                  icon: "success",
                  title: "Created!",
                  text: "Payment has been Created.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                that.cleandata();
              }
            });
          } else {
            let variant = "danger";
            this.$bvToast.toast("DTM amount or Donate amount required", {
              title: `Error`,
              variant,
              solid: true,
            });
          }
        } else {
          let variant = "danger";
          this.$bvToast.toast("Account is Inactive!", {
            title: `Error`,
            variant,
            solid: true,
          });
        }
      } else {
        let variant = "danger";
        this.$bvToast.toast("DTM ID, Trx Date and Trx/Money Recept Number is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    updatetran() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);
      console.log(that.selected);

      if (!that.selected == "") {
        this.$swal({
          title: "Enter Money Recept Number",
          input: "text",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Pay",
          showLoaderOnConfirm: true,
          preConfirm(number) {
            that.$http
              .post(`/dtm/reg/pay/${userdata.id}/${that.selected}/`, {
                moneyrecept: number,
                userName: userdata.username,
                message: `Cash Payment successfull by ${userdata.username}`,
              })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                console.log(error);
              });
          },
        }).then((result) => {
          if (result.value) {
            this.$swal({
              title: `Payment Success`,
              // imageUrl: result.value.avatar_url,
              customClass: { confirmButton: "btn btn-primary" },
            });
            that.cleandata();
          }
        });
      } else {
        let variant = "danger";
        this.$bvToast.toast("Transaction Id is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    cleandata() {
      this.number = "+880";
      this.fullName = "";
      this.mobile1 = "";
      this.dateOfBirth = "";
      this.opening_date = "";
      this.panding_amount = "";
      this.advance_amount = "";
      this.month = 0;
      this.data = {};
      this.tran_date = "";
      this.status = null;
      this.selecteddtm = null;
      this.selected = null;
      this.optionsdtm = [];
      this.options = [];
      this.formdata.moneyrecept = "";
      this.formdata.number = "";
      this.formdata.account = "";
      this.formdata.pay_type = "Bkash";
      this.formdata.dtm_amount = 0;
      this.formdata.donate_amount = 0;
      this.getdata();
    },
    gettran() {
      const that = this;
      if (!this.number == "") {
        this.$http
          .get(`/dtm/sundy/pay/getmdata/${that.number}/`)
          .then((response) => {
            if (response.data.message) {
              that.cleandata();
              let variant = "danger";
              this.$bvToast.toast("MFS Number Not Found", {
                title: `Error`,
                variant,
                solid: true,
              });
            } else {
              this.data = response.data;
              // this.fullName = response.data.dtm_profile.fullName;
              // this.mobile1 = response.data.dtm_profile.mobile1;
              // this.dateOfBirth = response.data.dtm_profile.dateOfBirth;
              // this.opening_date = response.data.created;
              // this.panding_amount = response.data.account.panding_amount;
              // this.advance_amount = response.data.account.advance_amount;
              this.status = true;
              this.optionsdtm = [];
              for (let i = 0; i < this.data.dtms.length; i++) {
                let datanew = {
                  value: this.data.dtms[i].id,
                  text: `ID: ${that.data.dtms[i].id} | Full Name: ${
                    that.data.dtms[i].fullName
                  } ----- ${that.data.dtms[i].familyType}: ${
                    that.data.dtms[i].familyName
                  } | Primery Mobile: ${
                    that.data.dtms[i].mobile1
                  } | Secendery Mobile: ${
                    that.data.dtms[i].mobile2 || "Number Not Found!"
                  }  `,
                };
                this.optionsdtm.push(datanew);
              }
              this.options = [];
              for (let i = 0; i < this.data.trans.length; i++) {
                let datanew = {
                  value: this.data.trans[i].id,
                  text: `Tran Date: ${that.data.trans[i].tran_date} | Tran Id: ${that.data.trans[i].id} ----- PayType: ${that.data.trans[i].pay_type} | Total Amount: ${that.data.trans[i].total_amount}BDT | DTM ID: ${that.data.trans[i].dtm_id} | Customer ID: ${that.data.trans[i].customer_id} | Payment status: ${that.data.trans[i].status} | Created By: ${that.data.trans[i].created_by}  `,
                };
                this.options.push(datanew);
              }
            }
          })
          .catch((error) => console.log(error));
      } else {
        let variant = "danger";
        this.$bvToast.toast("Pls Enter Customer Id", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    getdata() {
      this.$http
        .get(`/dtm/sundy/pay/mobile/${this.number || 0}/`)
        .then((response) => {
          this.predata = response.data;
        })
        .catch((error) => console.log(error));
    },
    getip() {
      axios
        .get("https://get.geojs.io/v1/ip/geo.json")
        .then((response) => {
          this.geo = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
  created() {
    this.getdata();
    this.getip();
  },
};
</script>